<template>
  <CenteredLayout id="signin">
    <div>
      <h1>Take your Research<br />to the Next Level</h1>

      <ul class="points">
        <li><font-awesome-icon icon="check" /> Collect your data securely</li>
        <li><font-awesome-icon icon="check" /> Easy statistical analysis</li>
        <li><font-awesome-icon icon="check" /> Charts and texts for your article</li>
      </ul>
    </div>

    <div v-if="step < 3">
      <form class="box" role="form" method="post" v-if="step == 1" @submit.prevent="checkFirstForm">
        <h2 style="margin-top: 0">Create your free account</h2>

        <slot></slot>

        <Messages :messages="messages" />

        <div class="entry">
          <label for="Email">Professional email:</label>
          <div style="position: relative">
            <font-awesome-icon class="input-warn" icon="exclamation-circle" v-show="is_email_exists" />
            <input
              type="email"
              :class="['form-control', is_email_exists ? 'error' : '']"
              placeholder="Professional email"
              name="Email"
              id="Email"
              v-model="email"
              @change="on_email_change($event)"
              required
              autofocus
            />
            <div v-show="is_email_exists" class="error">
              This account already exists.
              <a @click.prevent="password_recall">Lost the password ?</a>
            </div>
          </div>
        </div>

        <div style="margin-top: 2em">
          <label for="Password">Password:</label>
          <PasswordInput
            name="new-password"
            :password="password"
            @change="password = $event"
            @error="is_password_bad = $event"
          ></PasswordInput>
        </div>

        <div class="entry">
          <label for="Profession">Profession:</label>
          <select id="profession" v-model="Profession" class="form-control" required>
            <option></option>
            <option>Doctor</option>
            <option value="Medicine resident">Medicine resident (interne)</option>
            <option>Research assistant</option>
            <option>Statistician</option>
            <option>Pharmaceutic professional</option>
            <option>Device manufacturer</option>
            <option value="Other">My profession is not in the list</option>
          </select>
        </div>

        <div style="margin-top: 2em">
          <input type="checkbox" name="terms" id="terms" v-model="tou" style="margin-bottom: 1em" required />
          <label for="terms" style="font-weight: normal"
            >I have read and I accept the
            <a href="https://www.easymedstat.com/terms-of-use">terms of use</a>
            of the service.</label
          >
        </div>

        <div style="text-align: center">
          <button
            class="btn btn-primary"
            type="submit"
            style="margin-left: 1em"
            :disabled="is_email_exists || is_password_bad"
          >
            Create my account
          </button>
        </div>
      </form>
      <div style="text-align: right" v-show="step == 1">
        <router-link to="/login">Already have an account ? Sign-in</router-link>
      </div>
      <form class="box" role="form" method="post" v-if="step == 2" @submit="signUp">
        <h2 style="margin-top: 0">Step 2/2</h2>

        <Messages :messages="messages" />

        <div>
          <label for="given-name">First name:</label>
          <input
            type="text"
            class="form-control"
            placeholder="Your first name"
            name="given-name"
            ref="first_name_input"
            v-model.trim="FirstName"
            minlength="2"
            required
            autofocus
          />
        </div>

        <div style="margin-top: 2em">
          <label for="family-name">Last name:</label>
          <input
            type="text"
            class="form-control"
            placeholder="Your last name"
            name="family-name"
            v-model.trim="LastName"
            minlength="2"
            required
          />
        </div>

        <div>
          <label for="country">Country:</label>
          <CountrySelector v-model="CountryCode"></CountrySelector>
        </div>

        <div v-if="!is_academic">
          <label for="phone">Professional phone:</label>
          <input
            type="tel"
            class="form-control"
            placeholder="+55 55 55 55 55"
            pattern="^\+*[0-9 ,\.\-]+$"
            name="phone"
            v-model.trim="phone"
            required
          />
        </div>

        <div style="margin-top: 2em">
          <label for="city">City:</label>
          <input type="text" class="form-control" placeholder="Your city" name="locality" v-model="City" />
        </div>

        <div style="margin-top: 2em">
          <input type="checkbox" name="mail_list" id="mail_list" v-model="update_email_subscription" />
          <label for="mail_list" style="font-weight: normal"> Please keep me informed of EasyMedStat news.</label>
        </div>

        <div style="text-align: center">
          <button class="btn btn-primary" type="submit" style="margin-left: 1em" :disabled="disabled">
            Finalize my account
          </button>
        </div>
      </form>
    </div>
  </CenteredLayout>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import PasswordInput from "@/components/PasswordInput.vue";
import Messages from "@/components/Messages.vue";
import CountrySelector from "./CountrySelector.vue";
import CenteredLayout from "./CenteredLayout.vue";
import router from "@/router/index";
import mixpanel from "@/ems/mixpanel";
import { WSClient, ApiMessage } from "@/ems/WSClient";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { HTMLInputElement } from "happy-dom";

const props = defineProps<{
  initial_messages: ApiMessage[];
  initial_email: string;
  redir_on_success: string;
}>();

const route = useRoute();
const store = useStore();

const step = ref(1),
  email = ref(""),
  password = ref(""),
  is_email_exists = ref(false),
  is_password_bad = ref(false),
  tou = ref(false),
  FirstName = ref(""),
  LastName = ref(""),
  CountryCode = ref("FR"),
  City = ref(""),
  Profession = ref("NC"),
  phone = ref(""),
  update_email_subscription = ref(false),
  messages = ref<ApiMessage[]>([]),
  loading = ref(false),
  client = new WSClient(loading, messages);

let mixPanelInstance: any = null;

const first_name_input = ref<HTMLDivElement>();

onMounted(() => {
  let ca = document.cookie.split(";"),
    had_creds = false;
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf("creds") == 0) {
      let credsData = c.substring("creds".length + 1, c.length).split(":");
      had_creds = true;
      email.value = credsData[0];
      password.value = credsData[1];
    }
  }

  mixpanel.getMixpanel(getCurrentInstance(), store).then((mp) => {
    mixPanelInstance = mp;
    mp && mp.track("signup1-v2-shown");
  });
});

const on_email_change = async (e: Event): Promise<boolean> => {
  const input = e.target as HTMLInputElement | null;
  if (!input) return Promise.resolve(true);
  return await check_email(input.value);
};

const check_email = async (email_val: string): Promise<boolean> => {
  const resp = await client.queryWs<{ exists: boolean }>("GET", "user_emailCheck", {
    email: email_val,
  });
  is_email_exists.value = resp.exists;
  return !resp.exists;
};

const checkFirstForm = async (e: Event) => {
  if (!(await check_email(email.value))) return;
  step.value = 2;
  mixPanelInstance && mixPanelInstance.track("signup2-v2-details-shown");
  first_name_input.value?.focus();
};

const signUp = (e: Event) => {
  e.preventDefault();

  if (FirstName.value.length < 2 || LastName.value.length < 2) return;
  loading.value = true;

  messages.value = [];

  let email_subscription = update_email_subscription.value
    ? ["system", "updates", "statistics", "partners"]
    : ["system"];

  store
    .dispatch("register", {
      Email: email.value,
      FirstName: FirstName.value,
      LastName: LastName.value,
      CountryCode: CountryCode.value,
      City: City.value,
      Profession: Profession.value,
      Pass: password.value,
      email_subscription: email_subscription,
      url_referer: document.referrer,
      phone: phone.value,
    })
    .then(async (result) => {
      const user_email = result && result.logged_user && result.logged_user.Email;
      if (user_email) {
        email.value = user_email;
        const ug = (<any>window).userGuiding;
        if (ug)
          ug.identify(result.logged_user.UserId, {
            email: result.logged_user.Email,
          });

        if (mixPanelInstance) {
          mixPanelInstance.identify(result.logged_user.UserId);
          mixPanelInstance.people.set({
            Email: email.value,
            "Registered on": new Date(),
            Profession: Profession.value,
            "Registration source": "Signup v2",
          });
          mixPanelInstance.track("user-registered", {
            "Registration source": "Signup v2",
            Email: email.value,
          });
        }

        if (props.redir_on_success) {
          router.goto(props.redir_on_success);
        } else {
          const referer = result.logged_user.url_referer;
          // We need some business specs to continue
          if (referer && referer == "kaplan-meier") {
            // router.push({ name: 'component_with_video_kaplan-meier'});
          } else {
            router.push({ name: "onboarding_welcome" });
          }
        }
      } else {
        alert("Registration error");
      }
    })
    .catch((res) => {
      messages.value = res.messages;
      loading.value = false;
    });
};

const password_recall = function () {
  router.push({ name: "ask_password", params: { email: email.value } });
};

const disabled = computed(() => {
  return loading.value || !password.value || !email.value || FirstName.value.length < 2 || LastName.value.length < 2;
});

const is_academic = computed(() => {
  return (
    Profession.value === "Medicine resident" ||
    Profession.value === "Doctor" ||
    Profession.value === "Research assistant" ||
    Profession.value === "Statistician"
  );
});

watch(email, (newVal: string) => {
  if (newVal) {
    check_email(newVal);
  }
});
</script>

<style scoped>
#signin {
  display: flex;
}

#signin > div {
  margin: 4rem auto;
  max-width: 45em;
  text-align: left;
}

#signin h1 {
  font-size: 3em;
  margin-bottom: 1em;
}

#signin ul.points {
  list-style: none;
  font-size: 1.5em;
  padding: 0;
  line-height: 3em;
}

#signin .box {
  padding: 4em 5em;
}

#signin h2 {
  font-size: 2.14em;
}

#signin label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}

#signin form > div {
  margin-bottom: 2em;
}

#signin form > div:last-child {
  margin-bottom: 0;
}

#signin .points svg {
  color: var(--green-main-color);
}

#signin input.error {
  border: 2px solid var(--red-danger-color);
}

#signin :deep(.input-warn.fa-exclamation-circle) {
  position: absolute;
  left: -1.5em;
  font-size: 1.8em;
  top: 0.2em;
  color: var(--red-danger-color);
}

.error {
  margin-top: 0.8em;
  color: var(--red-danger-color);
}
</style>
