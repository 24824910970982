<template>
  <div class="login_box box">
    <form id="loginform" role="form" method="post" @submit.prevent="login">
      <h2 style="margin-top: 0">Sign in</h2>

      <slot></slot>

      <Messages :messages="messages" />
      <template v-if="!messages || messages.length == 0">
        <div v-if="error == 409 || error == 7" class="alert alert-danger">
          <p style="margin-top: 0; font-weight: bold">
            You have been disconnected because someone else is using your account.
          </p>
          <p>Only one user can be connected with this email.</p>
          <p>
            If you don't know why this message appears, please change your password quickly. Your account may have been
            compromised.
          </p>
        </div>
        <div v-else-if="error == 401 || error == 8" class="alert alert-danger">
          Please, sign in to access this page.
        </div>
      </template>

      <div>
        <label for="Email">Email:</label>
        <input
          type="text"
          class="form-control"
          placeholder="Email"
          autocomplete="username"
          name="Email"
          id="Email"
          v-model="email"
          required
          autofocus
        />
      </div>

      <div style="margin-top: 2em">
        <label for="Pass">Password:</label>
        <PasswordInput
          name="pass"
          :ignore_strength="true"
          :password="password"
          @change="set_password($event)"
          placeholder="Password"
        >
        </PasswordInput>
      </div>

      <div style="display: flex; justify-content: space-between; align-items: baseline; margin-top: 2em">
        <router-link to="/signup">Create an account</router-link>
        <div>
          <a @click.prevent="password_recall">Lost password ?</a>
          <input type="hidden" name="site" value="app" />
          <button class="btn btn-primary" type="submit" style="margin-left: 1em">Sign in</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import router from "@/router/index";
import Messages from "@/components/Messages.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import { ApiMessage } from "@/ems/WSClient";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps<{
  initial_messages?: ApiMessage[];
  initial_email?: string;
  redir_on_success?: string;
  error?: number;
}>();

const email = ref(""),
  password = ref(""),
  messages = ref<ApiMessage[]>([]);

const login = () => {
    if (!email.value) {
      messages.value = [{ level: "error", text: "Email required" }];
      return;
    }
    if (!password.value) {
      messages.value = [{ level: "error", text: "Password required" }];
      return;
    }
    messages.value = [];
    store
      .dispatch("login", {
        login: email.value,
        pass: password.value,
      })
      .then((dispRes) => {
        if (dispRes.status > 199 && dispRes.status < 300) {
          const ug = (<any>window).userGuiding;
          if (ug)
            ug.identify(store.getters.logged_user.UserId, {
              email: email.value,
            });
          if (props.redir_on_success && props.redir_on_success != "reset") {
            router.goto(props.redir_on_success);
          } else {
            const vueRedir = sessionStorage.getItem("afterlogin");
            if (vueRedir) {
              router.push(JSON.parse(vueRedir));
            } else {
              router.push({ name: "SeriesList" });
            }
          }
        } else {
          messages.value = dispRes.messages || [];
        }
      })
      .catch((err) => {
        messages.value = err && err.messages;
      });
  },
  password_recall = () => {
    router.push({ name: "ask_password", params: { email: email.value } });
  },
  set_password = (inStr: string) => {
    password.value = inStr;
    console.log("Set password");
  };

watch(
  () => props.redir_on_success,
  (redir) => {
    if (redir == "reset") {
      console.log("Reset afterlogin in login");
      sessionStorage.removeItem("afterlogin");
    }
  },
  { immediate: true }
);

watch(
  () => props.initial_messages,
  (init_messages) => {
    messages.value = init_messages ?? [];
  },
  { immediate: true }
);

watch(
  () => props.initial_email,
  (mail) => {
    if (mail) email.value = mail;
  },
  { immediate: true }
);

onMounted(() => {
  console.log("Mount Login with redir", props.redir_on_success);
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login_box {
  margin: 7.5rem auto;
  max-width: 45em;
  padding: 4em 5em;
  text-align: left;
}

h2 {
  font-size: 2.14em;
}

label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}

.password {
  background-color: transparent;
  border: 0;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  font-size: 1em;
}
</style>
