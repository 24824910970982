import { createStore, Commit } from "vuex";
import { ApiMessage, ApiResponse } from "@/ems/WSClient";
import { LoggedUser } from "@/types/user";
import { Serie, Cat, Variable, Filter, FilterGroupLists } from "@/types/serie";
import client from "@/ems/ws_client";
import { buildParentChildObjects, parseIfExists } from "@/ems/utils";
import { handleCurrentSerieExtra } from "@/ems/format_extra";
import jwtDecode, { JwtPayload } from "jwt-decode";
import router from "@/router";

interface CatResponse extends ApiResponse {
  variables_categories: Cat[];
}
interface VarResponse extends ApiResponse {
  variables: Variable[];
}
interface AuthResponse extends ApiResponse {
  jwt: string;
}

const onSessionSuccess = (resp: AuthResponse, commit: Commit) => {
  if (resp.jwt) sessionStorage.setItem("jwt", resp.jwt);
  commit("auth_success", resp);
  return resp;
};

const onSessionError = (resp: ApiResponse, commit: Commit) => {
  commit("auth_error");
  commit("logout");
  return resp;
};
interface CatHeritageObject {
  parents?: Record<number | string, (number | string)[]>;
  children?: Record<number | string, (number | string)[]>;
}

interface FilterResponse {
  filters: object[];
  filter_history: object[];
  filter_groups: { own: object[]; predefined: object[] };
}

export interface State {
  logged_user: LoggedUser;
  status: "" | "loading" | "error" | "success";
  server_env: Record<string, string | number>;
  token: string;
  serie: Serie;
  serie_cats: Cat[];
  serie_vars: Variable[];
  cat_heritage_object: CatHeritageObject;
  serie_site_var?: Variable;
  serie_filtred_site_var_id: string;
  patient_locked: boolean;
  filters: Filter[];
  filter_history: Filter[];
  filter_groups: FilterGroupLists;
  organization: object;
  open_palette: boolean;
  new_patient_created: boolean;
  change_color_params: number;
  colors_params: object;
  ecrf_selected_cat: object;
  ecrf_auth_messages: ApiMessage[];
  user_edition_unauthorized: boolean;
  user_view_unauthorized: boolean;
  cat_progress: Record<number | string, number>;
  cat_total: Record<number | string, number>;
  global_progress_loading: Boolean;
  skipped_categories: number[];
  current_dynamically_changed_vars: Record<number | string, number | string>;
  current_conditionally_changed_vars: Record<number | string, number | string>;
  set_of_visible_cats: Set<number | string>;
}
export const store = createStore<State>({
  state: {
    logged_user: parseIfExists("logged_user") ?? {},
    status: "",
    server_env: {},
    token: sessionStorage.getItem("jwt") ?? "",
    serie: parseIfExists("serie") ?? "",
    serie_cats: parseIfExists("serie_cats") ?? [],
    serie_vars: parseIfExists("serie_vars") ?? [],
    cat_heritage_object: parseIfExists("cat_heritage_object") ?? {},
    serie_site_var: undefined,
    serie_filtred_site_var_id: parseIfExists("serie_filtred_site_var_id") ?? "",
    patient_locked: false,
    filters: parseIfExists("filters") ?? {},
    filter_history: parseIfExists("filter_history") ?? [],
    filter_groups: parseIfExists("filter_groups") ?? { predefined: [], own: [] },
    organization: {},
    open_palette: false,
    new_patient_created: false,
    global_progress_loading: false,
    change_color_params: 0,
    colors_params: {},
    ecrf_selected_cat: parseIfExists("ecrf_selected_cat") ?? {},
    ecrf_auth_messages: parseIfExists("ecrf_auth_messages") ?? [],
    user_edition_unauthorized: false,
    user_view_unauthorized: false,
    cat_progress: parseIfExists("cat_progress") ?? {},
    cat_total: parseIfExists("cat_total") ?? {},
    skipped_categories: parseIfExists("skipped_categories") ?? [],
    current_dynamically_changed_vars: parseIfExists("current_dynamically_changed_vars") ?? {},
    current_conditionally_changed_vars: parseIfExists("current_conditionally_changed_vars") ?? {},
    set_of_visible_cats: new Set(),
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, payload) {
      state.status = "success";
      state.token = payload && payload.jwt;
      state.logged_user = payload && payload.logged_user;
      sessionStorage.setItem("logged_user", JSON.stringify(payload.logged_user));
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.logged_user = {};
      sessionStorage.removeItem("logged_user");
      sessionStorage.removeItem("jwt");
      document.cookie = "PHPSESSID=; Max-Age=0";
    },
    tour_taken(state) {
      state.logged_user.has_taken_tour = true;
    },
    set_server_env(state, payload) {
      state.server_env = Object.assign(state.server_env, payload);
    },
    set_serie(state, payload) {
      state.serie = payload.serie;
      sessionStorage.setItem("serie", JSON.stringify(payload.serie));
    },
    set_patient_locked(state, payload) {
      state.patient_locked = payload.patient_locked;
    },
    set_organization(state, payload) {
      state.organization = payload;
    },
    set_filters(state, payload) {
      if (payload.filters) {
        state.filters = payload.filters;
        sessionStorage.setItem("filters", JSON.stringify(payload.filters));
      }
      if (payload.filter_history) {
        state.filter_history = payload.filter_history;
        sessionStorage.setItem("filter_history", JSON.stringify(payload.filter_history));
      }
      if (payload.filter_groups) {
        state.filter_groups = payload.filter_groups;
        sessionStorage.setItem("filter_groups", JSON.stringify(payload.filter_groups));
      }
    },
    set_serie_cats(state, payload) {
      state.serie_cats = payload.cats;
      sessionStorage.setItem("serie_cats", JSON.stringify(payload.cats));
    },
    set_serie_vars(state, payload) {
      state.serie_vars = payload.vars;
      sessionStorage.setItem("serie_vars", JSON.stringify(payload.vars));
    },
    set_cat_heritage_object(state) {
      const cat_parents = buildParentChildObjects(state.serie_cats);
      state.cat_heritage_object = cat_parents;
      sessionStorage.setItem("cat_heritage_object", JSON.stringify(cat_parents));
    },
    activate_palette(state) {
      state.open_palette = true;
    },
    desactivate_palette(state) {
      state.open_palette = false;
    },
    set_new_patient_created(state) {
      state.new_patient_created = true;
    },
    unset_new_patient_created(state) {
      state.new_patient_created = false;
    },
    update_params(state, payload) {
      state.colors_params = payload;
    },
    alert_color_params(state) {
      state.change_color_params = 1 - state.change_color_params;
    },
    load_serie_site_var: async function (state, payload) {
      const reponse = await payload.client.queryWs("GET", "variable_variable", {
        serie_id: payload.serie_id,
        category: true,
        role: "SITE",
        no_general: true,
      });
      const site_vars = reponse.variables;
      if (site_vars) {
        console.log("site loadded");
        state.serie_site_var = site_vars[0];
      }
    },
    set_serie_filtred_site_var_id(state, payload) {
      state.serie_filtred_site_var_id = payload.var_id;
    },
    set_ecrf_selected_cat(state, payload) {
      state.ecrf_selected_cat = payload.value;
    },
    set_ecrf_auth_messages(state, payload) {
      state.ecrf_auth_messages = payload.messages;
      sessionStorage.setItem("ecrf_auth_messages", JSON.stringify(payload.messages));
    },
    reset_ecrf_auth_messages(state) {
      state.ecrf_auth_messages = [];
      sessionStorage.setItem("ecrf_auth_messages", JSON.stringify([]));
    },
    set_user_edition_unauthorized(state, payload) {
      state.user_edition_unauthorized = payload.state;
    },
    set_user_view_unauthorized(state, payload) {
      state.user_view_unauthorized = payload.state;
    },
    update_cat_progress(state, payload) {
      for (const cat_id in payload.cat_progress) {
        state.cat_progress[cat_id] = payload.cat_progress[cat_id];
      }
      for (const cat_id in payload.cat_total) {
        state.cat_total[cat_id] = payload.cat_total[cat_id];
      }
    },
    update_one_cat_progress(state, payload) {
      state.cat_progress[payload.cat_id] = payload.cat_progress;
      state.cat_total[payload.cat_id] = payload.cat_total;
    },
    reset_cat_heritage_object(state) {
      state.cat_heritage_object = {};
    },
    set_skipped_categories(state, payload) {
      state.skipped_categories = payload.skipped_categories;
    },
    set_dynamically_changed_vars(state, payload) {
      state.current_dynamically_changed_vars = payload.dynamically_changed_vars;
    },
    set_conditionally_changed_vars(state, payload) {
      state.current_conditionally_changed_vars = payload.conditionally_changed_vars;
    },
    alter_set_of_visible_cats(state, payload) {
      if (payload.action == "add") state.set_of_visible_cats.add(payload.value);
      else if (payload.action == "delete") state.set_of_visible_cats.delete(payload.value);
    },
    reset_set_of_visible_cats(state) {
      state.set_of_visible_cats = new Set();
    },
  },
  actions: {
    async login({ commit }, creds) {
      commit("auth_request");
      return client
        .create(this, router)
        .query_ws2("session_login", "POST", null, creds)
        .then(
          (resp) => onSessionSuccess(resp, commit),
          (resp) => onSessionError(resp, commit)
        );
    },

    async session_check({ commit }, options) {
      const force = options && options.force_query;

      if (this.getters.isLoggedIn && !force) {
        const current_time = Date.now() / 1000;
        // check token exist and not expired
        const token = jwtDecode<JwtPayload>(this.getters.token);
        const token_expiration = token.exp ? token.exp : 0;
        if (this.getters.token && token_expiration > current_time) {
          return Promise.resolve({ logged_user: this.getters.logged_user });
        }
      }

      commit("auth_request");
      return client
        .create(this, router)
        .query_ws2("session_login", "GET")
        .then(
          (resp) => onSessionSuccess(resp, commit),
          (resp) => onSessionError(resp, commit)
        );
    },

    async register({ commit }, userData) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        client
          .create(this, router)
          .query_ws2("session_register", "POST", null, userData)
          .then((resp) => {
            if (resp && resp.logged_user && resp.logged_user.Email) {
              if (resp.jwt) sessionStorage.setItem("jwt", resp.jwt);
              commit("auth_success", resp);
              resolve(resp);
            }
          })
          .catch((resp) => {
            commit("auth_error");
            reject(resp);
          });
      });
    },

    async logout({ commit }) {
      commit("logout");
      client.create(this, router).query_ws2("session_logout", "POST");
      router.push({ name: "login" });
    },

    get_env_params({ commit }, names: string[]) {
      if (!names) return {};

      let foundAll = true,
        result: Record<string, string> = {};

      for (let i = 0; i < names.length; i++) {
        let envVal = this.getters.server_env(names[i]);
        if (!envVal) {
          foundAll = false;
          continue;
        }
        result[names[i]] = envVal;
      }
      if (foundAll) return Promise.resolve(result);
      return client
        .create(this, router)
        .query_ws2("env_param", "GET", {
          key: names.join("|"),
        })
        .then((resp) => {
          commit("set_server_env", resp);
          return resp;
        });
    },
    async get_env_param({ commit }, name: string) {
      let envVal = this.getters.server_env(name);
      if (envVal) {
        return envVal;
      }

      return client
        .create(this, router)
        .query_ws2("env_param", "GET", {
          key: name,
        })
        .then((resp) => {
          commit("set_server_env", resp);
          return resp[name];
        });
    },

    async open_serie({ commit }, payload) {
      console.log("Openning serie " + payload.serieId);
      return payload.client
        .queryWs("GET", "serie_serie", {
          serie_id: payload.serieId,
          patient_count: true,
          with_plan: true,
        })
        .then(async (result: { serie: Serie }) => {
          if (result.serie) {
            result.serie = handleCurrentSerieExtra(result.serie);
            commit({
              type: "set_serie",
              serie: result.serie,
            });
            if (payload.ignore_vars_data) {
              this.dispatch("load_serie_filters", payload);
            } else {
              this.dispatch("load_serie_filters", payload);
              await this.dispatch("load_serie_cats", payload);
              await this.dispatch("load_serie_vars", payload);
            }
          } else {
            router.push({
              name: "SeriesList",
              query: {
                e: 2,
              },
            });
          }
        })
        .catch((x: Error) => {});
    },
    async set_patient_locked({ commit }, payload) {
      commit({
        type: "set_patient_locked",
        patient_locked: payload,
      });
    },
    async load_serie_filters({ commit }, payload) {
      payload.client
        .queryWs("GET", "filter_session", {
          serie_id: payload.serieId,
          filter_history: true,
          filter_groups: true,
        })
        .then((resp: FilterResponse) => {
          commit({
            type: "set_filters",
            filters: resp.filters,
            filter_history: resp.filter_history,
            filter_groups: resp.filter_groups,
          });
        });
    },

    async load_serie_cats({ commit }, payload) {
      payload.client
        .queryWs("GET", "variable_varCatOfSerie", {
          serie_id: payload.serieId,
        })
        .then((resp: CatResponse) => {
          commit({
            type: "set_serie_cats",
            cats: resp.variables_categories,
          });
        });
    },
    async load_serie_vars({ commit }, payload) {
      payload.client
        .queryWs("GET", "variable_variable", {
          var_count: true,
          category: true,
          with_not_analysed: true,
          with_picture_exists: true,
          all_data: true,
          sid: payload.serieId,
        })
        .then((resp: VarResponse) => {
          commit({
            type: "set_serie_vars",
            vars: resp.variables,
          });
        });
    },
    async update_skipped_categories({ commit }, payload) {
      const cats_response = await payload.client.queryWs("GET", "patient_skippedCategories", {
        patient_id: payload.patient_id,
      });
      if (cats_response?.skipped_categories) commit("set_skipped_categories", cats_response);
    },
    async update_all_cats_progress({ commit, state }, payload) {
      state.global_progress_loading = true;
      let set_cat_ids = store.getters.set_of_visible_cats;
      set_cat_ids.delete(-1);
      const numeric_vars_ids = [...set_cat_ids];
      let cat_total: Record<string, number> = {};
      let cat_progress: Record<string, number> = {};
      const pid = payload.pid;
      const resp = await payload.client.queryWs(
        "POST",
        "patient_categoryProgress",
        {},
        { cat_ids: numeric_vars_ids, patient_id: pid }
      );
      for (let cat_id of numeric_vars_ids) {
        cat_total[cat_id] = resp["categories"][cat_id]["total"];
        cat_progress[cat_id] = resp["categories"][cat_id]["defined"];
      }
      if (payload.custom_only) {
        cat_total[-1] = store.getters.cat_total[-1];
        cat_progress[-1] = store.getters.cat_progress[-1];
      } else {
        const resp_identity_cat = await payload.client.queryWs("POST", "patient_categoryProgress", {
          patient_id: pid,
          cat_id: -1,
        });
        cat_total[-1] = resp_identity_cat["categories"][-1]["total"];
        cat_progress[-1] = resp_identity_cat["categories"][-1]["defined"];
      }
      commit("update_cat_progress", { cat_total: cat_total, cat_progress: cat_progress });
      state.global_progress_loading = false;
    },
    async update_specific_cat_progress({ commit }, payload) {
      const pid = payload.pid;
      const numeric_vars_ids = [payload.cat_id];
      const resp = await payload.client.queryWs(
        "POST",
        "patient_categoryProgress",
        {},
        { cat_ids: numeric_vars_ids, patient_id: pid }
      );
      const cat_total = resp["categories"][payload.cat_id]["total"];
      const cat_progress = resp["categories"][payload.cat_id]["defined"];
      commit("update_one_cat_progress", { cat_total: cat_total, cat_progress: cat_progress, cat_id: payload.cat_id });
    },
    check_filter_site({ commit }) {
      if (store.state.filters.length > 0) {
        console.log("filters:", store.state.filters);
        const first_site_filter_vars = store.state.filters
          .filter((aVar: Filter) => aVar?.variable?.Role === "SITE")
          .shift();
        if (first_site_filter_vars?.variable?.VarId !== undefined) {
          commit("set_serie_filtred_site_var_id", { var_id: first_site_filter_vars.variable.VarId });
        }
      }
    },
  },

  getters: {
    isLoggedIn: (state) => state.logged_user && !!state.logged_user.UserId,
    logged_user: (state) => state.logged_user,
    isAdmin: (state) => !!state.logged_user.is_admin,
    authStatus: (state) => state.status,
    serie: (state) => state.serie,
    serie_cats: (state) => state.serie_cats,
    serie_flat_cats: (state) => {
      if (state.serie_cats.length == 0) {
        return [];
      }
      return state.serie_cats.map((c: Cat) => flatten_cat(c)).reduce((a: Cat[], c: Cat[]) => a.concat(c));
    },
    serie_vars: (state) =>
      state.serie_vars.filter((v) => {
        return v.IsAnalyzed ?? true;
      }),
    all_serie_vars: (state) => state.serie_vars,
    serie_site_var: (state) => state.serie_site_var,
    patient_locked: (state) => state.patient_locked,
    filters: (state) => state.filters,
    filter_history: (state) => state.filter_history,
    filter_groups: (state) => state.filter_groups,
    user_edition_unauthorized: (state) => state.user_edition_unauthorized,
    user_view_unauthorized: (state) => state.user_view_unauthorized,
    cat_progress: (state) => state.cat_progress,
    cat_total: (state) => state.cat_total,
    global_progress_loading: (state) => state.global_progress_loading,
    cat_heritage_object: (state) => state.cat_heritage_object,
    skipped_categories: (state) => state.skipped_categories,
    set_of_visible_cats: (state) => state.set_of_visible_cats,
    current_dynamically_changed_vars: (state) => state.current_dynamically_changed_vars,
    current_conditionally_changed_vars: (state) => state.current_conditionally_changed_vars,
    organization: (state) => state.organization,
    server_env: (state) => (key: string) => {
      return (state.server_env as Record<string, string>)[key];
    },
    token: function (state) {
      return state.token;
    },
  },
});
function flatten_cat(cat: Cat): Cat[] {
  if (!cat.children) {
    return [cat];
  }
  let child_cats = cat.children?.map((c) => flatten_cat(c)).reduce((a, c) => a.concat(c));
  return [cat].concat(child_cats);
}
