<template>
  <div id="app">
    <TopNav></TopNav>
    <div v-if="seriePatientWarn" class="alert-warning" style="padding: 1rem">
      Your series has exceeded the limit of 2000 patients. It may decrease the speed of the calculations.
    </div>
    <!-- start router view -->
    <main>
      <router-view @hide_login_bt="changeLoginBt($event)" />
    </main>
    <!-- end router view -->
    <FooterNav v-if="!isLoggedIn" public_site_base_url="https://easymedstat.com"></FooterNav>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

import TopNav from "@/components/TopNav.vue";
import FooterNav from "@/components/FooterNav.vue";
import "nprogress/nprogress.css";
import "@/assets/css/blink_animation.css";

const store = useStore();
const route = useRoute();
const hide_login_button = ref(false);
const logged_user = ref({ FirstName: "", LastName: "", UserId: 0 });

const changeLoginBt = function (val: boolean) {
  hide_login_button.value = val;
};
const seriePatientWarn = computed(function () {
  return (
    isLoggedIn &&
    route.params?.series_id &&
    store.getters.serie?.NumberOfPatients > 2000 &&
    !route.fullPath.includes("/patient/") &&
    !route.fullPath.includes("/automl/")
  );
});
const isLoggedIn = computed(function () {
  return store.getters.isLoggedIn;
});
const toast = useToast();
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  --green-main-color: #018469;
  --green-medium-color: #00e59f;
  --green-light-color: #ddf5ed;
  --blue-main-color: #3481ff;
  --pink-main-color: #f3438e;
  --blue-light-color: #d5ebf8;
  --grey-13: hsl(0, 0%, 13%);
  --grey-20: hsl(0, 0%, 20%);
  --grey-32: hsl(0, 0%, 32%);
  --grey-46: hsl(0, 0%, 46%);
  --grey-64: hsl(0, 0%, 64%);
  --grey-80: hsl(0, 0%, 80%);
  --grey-90: hsl(0, 0%, 90%);
  --grey-95: hsl(0, 0%, 95%);
  --grey-98: hsl(0, 0%, 98%);
  --red-danger-color: #a94442;
  --red-danger-light-color: #f2dede;
  --red-danger-border-color: #ebccd1;
  --yellow-warn-color: #fdaa11;
  --yellow-warn-light-color: #fff7e3;
  --font-main: Arial, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.Vue-Toastification__toast--default {
  background-color: var(--green-main-color) !important;
  color: #fff;
}

.Vue-Toastification__toast--info {
  background-color: var(--blue-main-color) !important;
  color: #fff;
}

.Vue-Toastification__toast--success {
  background-color: var(--green-medium-color) !important;
  color: #fff;
}

.Vue-Toastification__toast--error {
  background-color: var(--pink-main-color) !important;
  color: #fff;
}

.Vue-Toastification__toast--warning {
  background-color: #ffc107 !important;
  color: #fff;
}

.toast-comtainer {
  min-width: fit-content !important;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  background-color: var(--grey-98);
}

svg,
button {
  font-size: 14px;
}

body {
  color: var(--grey-20);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-main);
  font-size: 14px;
  line-height: 1.428571429;
}

#app {
  background-color: var(--grey-98);
}

main {
  position: relative;
  margin: 1rem 1rem 1rem 1rem;
  min-height: 500px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h3 {
  font-size: 1.7em;
}

h4 {
  font-size: 1.3em;
}

/* Links */

a {
  color: #428bca;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus {
  color: #2a6496;
  text-decoration: underline;
}

a:active,
a:hover {
  outline: 0;
}

a.text-green-light:hover,
a.text-green-light:active,
a.text-green-light:visited,
a.text-green-light:link {
  color: var(--gren-light-color);
  text-decoration: none;
}

/* Buttons */

button {
  cursor: pointer;
}

.btn {
  display: inline-block;
  padding: 8px 12px 8px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.btn:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover,
.btn:focus {
  color: var(--grey-20);
  text-decoration: none;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-default {
  color: var(--grey-20);
  background-color: white;
  border-color: var(--grey-80);
}

.btn-default.focus,
.btn-default:focus,
.open .dropdown-toggle.btn-default {
  background-color: var(--grey-95);
  border-color: var(--grey-46);
}

.btn-default:hover {
  background-color: var(--grey-95);
  border-color: hsl(0, 0%, 68%);
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: white;
  border-color: hsl(0, 0%, 80%);
}

.btn-default .badge {
  color: white;
  background-color: white;
}

.btn-light {
  color: #222;
  background-color: #f1f1f1;
  border-color: #e1e1e1;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.open .dropdown-toggle.btn-light {
  color: hsl(0, 0%, 23%);
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 72%);
  outline: 0;
}

.btn-primary {
  color: white;
  background-color: var(--blue-main-color);
  border-color: var(--blue-main-color);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: white;
  background-color: #3276b1;
  outline: 0;
  border-color: #285e8e;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #428bca;
  border-color: #357ebd;
}

.btn-primary .badge {
  color: #428bca;
  background-color: white;
}

.btn-warning {
  color: white;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: white;
  background-color: #ed9c28;
  border-color: #d58512;
}

.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: white;
}

.btn-danger {
  color: white;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: white;
  background-color: #d2322d;
  border-color: #ac2925;
}

.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: white;
}

.btn-success {
  color: white;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: white;
  background-color: #47a447;
  border-color: #398439;
}

.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: white;
}

.btn-info {
  color: white;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: white;
  background-color: #39b3d7;
  border-color: #269abc;
}

.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: white;
}

.btn-link {
  font-weight: normal;
  color: #428bca;
  cursor: pointer;
  border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}

.btn-link:hover,
.btn-link:focus {
  color: #2a6496;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: hsl(0, 0%, 60%);
  text-decoration: none;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-icon {
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.btn-icon:hover {
  color: var(--grey-32);
}

.btn-icon:disabled {
  color: var(--grey-80);
}

/* Alerts */

.alert {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 2px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert a {
  text-decoration: underline;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable {
  padding-right: 35px;
}

.alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  color: var(--green-main-color);
  background-color: var(--green-light-color);
  border-color: var(#85e2ce);
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info > *:first-child {
  margin-top: 0;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger,
.alert-error {
  color: var(--red-danger-color);
  background-color: var(--red-danger-light-color);
  border-color: var(--red-danger-border-color);
}

.alert-danger hr,
.alert-error hr {
  border-top-color: var(--red-danger-border-color);
}

.alert-danger .alert-link,
.alert-error .alert-link {
  color: #843534;
}

/* Boxes */
.box {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  box-shadow: 2px 2px #f2f2f2;
}

.vertical-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5em;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  background-color: white;
  background-image: none;
  border: 1px solid var(--grey-95);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.075);
  -webkit-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow:
    0 1px 1px rgb(0 0 0 / 8%),
    0 0 8px rgb(102 175 233 / 60%);
  box-shadow:
    0 1px 1px rgb(0 0 0 / 8%),
    0 0 8px rgb(102 175 233 / 60%);
}
.form-control:disabled {
  background: #f2f2f2;
  color: var(--grey-80);
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 0px solid #ddd;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fafafa;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #f5f5f5;
}

.studyblock {
  margin-bottom: 1em;
  background-color: white;
  border: 1px solid var(--grey-95);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.studycontent {
  padding: 1rem 2rem;
}

.studyblock h4 {
  margin-top: 10px;
}

.studyblock .header {
  position: relative;
  color: black;
  padding: 1.15em 1.15em 1.15em 2.3em;
}

.studyblock .header .title {
  display: inline-block;
  font-size: 1.4em;
  font-weight: bold;
  margin-left: 0.5em;
  margin-right: 1em;
}

.studyblock .header .symbol {
  position: absolute;
  left: 3em;
  top: 1.6em;
}

.studyblock .header .toggle {
  position: absolute;
  right: 1.5em;
  top: 35%;
  cursor: pointer;
}

.tooltiped {
  position: relative;
}

.tooltiped:hover {
  cursor: pointer;
}

.tooltiped .tooltiptext {
  word-break: break-all;
  visibility: hidden;
  width: 21em;
  background-color: #2b2b2ceb;
  color: white;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.7rem 0.8rem;
  position: absolute;
  z-index: 1040;
  line-height: 1.2rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  white-space: normal;
  transition:
    transform 0.3s,
    opacity 0.25s,
    left 0.1s;
  bottom: 0px;
  left: 0px;
  opacity: 0;
}
.tooltiped .tooltiptext-bottom {
  bottom: unset;
  top: 6rem;
}

.tooltiped .tooltiptext-left {
  left: unset;
  right: 1rem;
}
.tooltiped .tooltiptext-right {
  left: 1rem;
  right: unset;
}
.tooltiped:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  transform: translateY(-21px);
}

input.error {
  border: 1px solid var(--red-danger-color);
}

.input-error {
  border-color: rgb(245, 44, 44);
}

p.error,
div.error {
  color: var(--red-danger-color);
  font-weight: bold;
  font-style: italic;
  padding: 0.3em 0;
}

.gender {
  font-size: 1.5em;
  vertical-align: middle;
}

.gender.M {
  color: var(--blue-main-color);
}

.gender.W {
  color: var(--pink-main-color);
}

#nprogress .bar {
  height: 5px;
}

#container {
  padding: 40px;
  background-color: grey;
  color: white;
}

#nprogress .bar {
  background: var(--blue-main-color);
}

#nprogress .spinner-icon {
  border-top-color: var(--green-main-color);
  border-left-color: var(--green-main-color);
}

.carret {
  width: 0.75em;
}

svg.icon {
  overflow: visible;
  box-sizing: content-box;
  display: inline-block;
  height: 1em;
  vertical-align: -0.125em;
}

h2.green {
  color: var(--green-main-color);
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 0;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.close-xmark {
  cursor: pointer;
  float: right;
  margin-left: 5px;
}

.close-xmark:hover {
  color: var(--red-danger-color);
}

.loading-mask {
  background: #e2e2e2 !important;
  color: #e2e2e2 !important;
  border-radius: 10px !important;
}

.custom-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  margin: 1em 0;
  padding: 2.5em;
  border-radius: 8px;
}

.grayed-section {
  background-color: var(--grey-95);
}

.generic-flex-row {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;
}

.generic-flex-row-center {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.generic-flex-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.generic-flex-column-left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: left;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

body::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
</style>
